<template>
      <div class="">
        <div>
          <p>
            <strong>{{strip.displayName}}</strong>
            <br />
            {{strip.header}}
          </p>            
        </div>
        <div class="text-center">
          <a v-bind:href="strip.permalink" target="_blank" >
            <img v-bind:src="strip.imageLink" v-bind:title="strip.tooltip" class="img-fluid" />
          </a>
        </div>
      </div>
      <br />
</template>

<script>

export default {
  name: 'Comic',
  props: ['comic'],
  data() {
    return {
      strip: this.comic
    }
  }
}
</script>

<style>
li {
    text-align: left;
}
</style>