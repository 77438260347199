<template>
  <div class="p-2 bg-light">
    <div class="container">
      <h1 class="display-4">Comic Book</h1>
      <p class="lead">One place to see all your favorite comics</p>
    </div>
  </div>
  <div class="container-lg">
    <Comic
      v-for="comic in comics"
      v-bind:comic="comic"
      v-bind:key="comic.name"
    ></Comic>
  </div>
</template>

<script>
import axios from 'axios';
import Comic from './components/Comic.vue';

export default {
  name: 'App',
  components: {
    Comic
  },
  data() {
    return {
      comics: []
    }
  },
  created() {
    axios.get('https://comicbook-ws.sbanka.io/comic')
    .then(response => {
      this.comics = response.data;
    })
  }
}
</script>
